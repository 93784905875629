// Ajax handle
// -----------
var AjaxException = function (message, context) {
  this.name = 'SMweb ajax exception';
  this.message = message;

  if (typeof context !== 'undefined') {
    this.context = context;
  }

  this.toString = function () {
    return this.name + ': ' + this.message;
  };
};

var async = function (url, data, callback) {
  if (url) {

    if (typeof data === 'function') {
      callback = data;
      data = {};
    }

    $.post(url, data).done(function (payload) {
      if (payload.hasOwnProperty('status')) {

        if (((payload['status'] === 'danger') ||
          (payload['status'] === 'warning')) &&
          !payload.hasOwnProperty('message')) {
          throw new AjaxException(
            'Unknown response message. Do include a message in your response when status is danger or warning.',
            payload);
        }
      }
      else {
        throw new AjaxException(
          'Unknown response status. Do include a status in your response.',
          payload);
      }

      if (payload.hasOwnProperty('location')) {
        window.location = payload['location'];
      }

      if (typeof callback === 'function') {
        callback(payload);
      }

    }).fail(function (e) {
      throw new AjaxException('Error calling ' + url, e);
    });
  }
  else {
    throw new AjaxException('No url provided.');
  }
};

var redraw = function (url, data, targets, callback) {
  if (url) {

    if (typeof targets === 'function') {
      callback = targets;
    }

    var timeout;
    if ((typeof targets !== 'undefined') &&
      (typeof targets !== 'function') &&
      (targets.length)) {
      timeout = setTimeout(function () {
        $(targets).each(function () {
          if ($(this).css('position') === 'static') {
            $(this).css({ position: 'relative' });
          }

          $(this).append('<div class="loader"></div>');
        });
      }, 500);
    }

    async(url, data, function (payload) {
      clearTimeout(timeout);

      for (var id in payload) {
        if (payload.hasOwnProperty(id)) {

          var target = $('#' + id);
          if (target.length) {
            target.html(payload[id]);
          }
        }
      }

      if (typeof callback === 'function') {
        callback(payload);
      }
    });
  }
  else {
    throw new AjaxException('No url provided.');
  }
};

/* --- Anchor handle --- */

function scrollAnchor () {
  var url = $(location).attr('href').split('#')[0];

  if (($(this).attr('href') === undefined) ||
    !$(this).attr('href').match('^(' + url + ')?\#.+$')) {
    return true;
  }
  else {
    var id = $(this).attr('href').replace(url, '');
    if ($(id).length) {
      scrollTo(id, 500, 80);
    }
    return false;
  }
}

function scrollTo (id, speed, mOffset) {
  $('html,body').stop().animate(
    {
      scrollTop: $(id).offset().top - mOffset + 5
    },
    speed,
    function () {}
  ); //+5 je kvuli firefoxu, kterej neumi jezdit spravne na kotvicky
}

$(document).on('click', 'a', scrollAnchor);
