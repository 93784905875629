function initDataTable (target, options) {
  var data = {
    destroy: true,
    language: {
      processing: 'Načítám...',
      lengthMenu: 'Zobraz záznamů _MENU_',
      zeroRecords: 'Žádné záznamy',
      info: 'Zobrazuji _START_ až _END_ z celkem _TOTAL_ záznamů',
      infoEmpty: '',
      infoFiltered: '',
      infoPostFix: '',
      search: 'Hledat:',
      url: '',
      paginate: {
        first: 'První',
        previous: 'Předchozí',
        next: 'Další',
        last: 'Poslední'
      }
    }
  };

  $.extend(data, options);
  $(target).DataTable(data);
}

$(document).on('click', '.confirm', function () {
  if (confirm($(this).data('message'))) {
    return true;
  }
  else {
    $(this).blur();
    return false;
  }
});

function initAutocomplete () {
  $('.lang-autocomplete').each(function () {
    var autocompleteUrl = $(this).data('autocomplete-url');

    $(this).autocomplete({ hint: false }, [
      {
        source: function (query, cb) {
          $.ajax({
            url: autocompleteUrl + '?query=' + query
          }).then(function (data) {
            cb(data.languages);
          });
        },
        displayKey: 'code',
        debounce: 500 // only request every 1/2 second
      }
    ]);
  });
}

$(function () {

  $('.select2').select2();

  $('.disabled').click(function (e) {
    e.preventDefault();
  });

  initDataTable('.datatable', {
    pageLength: 25,
  });

  initAutocomplete();

});
